<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;">
            <div slot="header" class="my-header">
                <div class="c-2 f16 pointer" @click="$oucy.back()">&lt;返回</div>
                 <div>
                        <el-checkbox v-model="checked">全选</el-checkbox>
                        <el-button  class="m-l-15" icon="el-icon-delete" type="danger" size="mini">删除照片</el-button>
                        <el-button type="default" size="mini">上传照片</el-button>
                </div>
            </div>
            <div>
                <div class="items">
                    <div v-for="(v,i) of 10" class="item m-b-35" :key="i">
                        <img src="@/assets/images/fur1.png">
                        <div class="m-t-15">
                            <div class="">
                                <el-checkbox v-model="checked" class="f16 c-3 bold">沙发相册1</el-checkbox>
                            </div>
                            <div class="f14 c-2 m-t-10">6月15日</div>
                            <div class="m-t-10 p-b-20">
                                <el-button size="mini">删除相册</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400">
                  </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";

export default {
    name: "Photo",
    data() {
        return {
            checked: true,
            hasList: true,
            list: [{ name: '家具分类1', id: '1' }, { name: '家具分类2', id: '2' }],
            activeName: '1',
        }
    },
    mounted() {},
    methods: {
        handleClick() {},
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor:pointer;
}
</style>